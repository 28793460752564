import { saveAs } from 'file-saver'

export default {
  data: () => ({
    loaderRender: false,
    renderUrl: null,
    modalRender: false,
    errorPDF: false
  }),
  created () {
    this.$loadScript('/static/js/pdfobject.min.js')
  },
  methods: {
    /**
     * Generate a PDFObject of document PDF blob file and set renderUrl to be downloaded
     *
     * @param {store} string The store that triggers the API call
     * @param {resource} string Custom API resource when store is different
     * @param {detail} string API detail path
     * @param {id} string Object ID to be obtained
     * @public This is a public method
     */
    renderDocument (store, resource = null, detail = 'pdf', id = this.$route.params.id, query = {}) {
      this.modalRender = true
      // toDo refactoring - consider 2 cases for tables and retrieve
      // if (this.renderUrl && !['DocumentsList', 'PurchaseList'].includes(this.$route.name)) {
      //   return false
      // }
      this.loaderRender = true
      this.$store.dispatch(`${store}/RETRIEVE`, {
        resource: resource === null ? store : resource,
        detail: detail,
        id: id,
        setDetail: false,
        loader: false,
        query
      })
      .then((response) => {
        const byteCharacters = atob(response.data.content)
        const byteNumbers = new Array(byteCharacters)
        for (let i = 0; i < byteCharacters?.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const file = new Blob([byteArray], {type: 'application/pdf'})
        this.renderUrl = URL.createObjectURL(file)
        this.$nextTick(() => {
          window.PDFObject.embed(this.renderUrl, '#render')
        })
      })
      .catch((error) => {
        if (error?.response?.status !== 400) this.errorPDF = true
        this.renderUrl = null
      })
      .finally(() => {
        this.loaderRender = false
      })
    },
    /**
     * Generate a download of document XML file
     *
     * @param {store} string The store that triggers the API call
     * @param {name} string Name of document when is downloaded
     * @param {resource} string Custom API resource when store is different
     * @param {detail} string API detail path
     * @param {id} string Object ID to be obtained
     * @public This is a public method
     */
    downloadFile (store, name, resource = null, detail = 'xml', id = this.$route.params.id) {
      this.$store.dispatch(`${store}/RETRIEVE`, {
        resource: resource === null ? store : resource,
        detail: detail,
        id: id,
        setDetail: false,
        loader: false
      })
      .then((response) => {
        if (!response?.data?.content) {
          response.data.forEach((item) => {
            const content = Buffer.from(item.content, 'base64')
            saveAs(new Blob([content], {type: `application/${item.extension}`}), `${name.replace(/[ - ]/g, '').replace(/[ ]/g, '_')}.${item.extension}`)
          })
        } else {
          const content = Buffer.from(response.data.content, 'base64')
          saveAs(new Blob([content], {type: `application/${response.data.extension}`}), `${name.replace(/[ - ]/g, '').replace(/[ ]/g, '_')}.${response.data.extension}`)
        }
      })
    }
  }
}
