<template>
  <div v-if="!['AccountMembers', 'AccountGroups', 'DocumentsBatchListCreate'].includes($route.name)">
    <v-skeleton-loader class="rounded-t-md" transition="fade-transition" type="table-thead" />
    <v-skeleton-loader class="rounded-b-md" transition="fade-transition" type="table-tbody" />
  </div>
  <v-col cols="12" class="pa-0" v-else>
    <v-row>
      <v-col cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2" v-for="action in 5" :key="action">
        <v-skeleton-loader class="rounded-md" height="110" type="image" />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="3" v-for="action in 6" :key="action">
        <v-skeleton-loader min-width="216" height="110" type="image" />
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="2">
        <v-skeleton-loader class="rounded-md" type="text" />
      </v-col>
      <v-spacer />
      <v-col cols="1" class="mr-10">
        <v-skeleton-loader class="rounded-md" type="text" />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
export default {
  data: () => ({
    attrs: {
      boilerplate: true,
      elevation: 0
    }
  })
}
</script>